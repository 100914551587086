@charset "UTF-8";
html,
body {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden; }

body {
  --mdc-icon-font: "Material Icons Sharp";
  display: flex;
  flex-flow: column;
  margin: 0;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  padding: 0;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box; }

* {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent; }

:focus {
  outline: none; }

@font-face {
  font-family: "Material Icons Sharp";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("./assets/fonts/material-icons-sharp.woff2") format("woff2"), url("./assets/fonts/material-icons-sharp.woff") format("woff"); }

.material-icons-sharp {
  font-family: "Material Icons Sharp";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga'; }

.page-header {
  position: relative;
  flex: 0 0 auto;
  margin: 16px 0;
  padding: 0 16px;
  text-align: center;
  line-height: 1;
  color: #06046e;
  font-size: 17px;
  font-weight: 700; }
  .page-header .back {
    --mdc-icon-size: 40px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: #06046e; }

.page {
  display: flex;
  flex-flow: column;
  z-index: 1;
  background-color: #fff;
  background-image: url("./assets/images/background.png");
  background-size: 100% 100%;
  overflow: hidden;
  box-sizing: border-box; }
  .page .page-title {
    color: #06046e; }
    .page .page-title.home {
      margin: 16px 0 0;
      text-align: center;
      line-height: 31px;
      font-size: 25px;
      font-weight: 300; }
  .page.dialog {
    position: absolute;
    transition: visibility 0s linear .2s;
    animation: modal-page-leave .2s;
    visibility: hidden;
    z-index: 100;
    width: 100%;
    height: 100%; }
    .page.dialog[active] {
      transition: none;
      animation: modal-page-enter .2s;
      visibility: visible; }

@keyframes modal-page-enter {
  0% {
    transform: translate3d(0, 6%, 0);
    opacity: 0; }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1; } }

@keyframes modal-page-leave {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1; }
  100% {
    transform: translate3d(0, 6%, 0);
    opacity: 0; } }

.main-player {
  display: block;
  margin: 8px 16px 24px; }

.featured-program {
  margin: 0 16px 24px;
  border-radius: 6px;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.09);
  background-color: #fff;
  padding: 24px 32px; }
  .featured-program .title {
    margin: 0;
    text-align: center;
    line-height: 22px;
    color: #06046e;
    font-size: 19px;
    font-weight: 700; }
  .featured-program .subtitle {
    text-align: center;
    line-height: 20px;
    color: rgba(38, 38, 38, 0.5);
    font-size: 13px; }
  .featured-program .primary-quote {
    margin-top: 16px;
    text-align: center;
    line-height: 22px;
    color: #06046e;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    quotes: '„' '”'; }
    .featured-program .primary-quote::before {
      content: open-quote; }
    .featured-program .primary-quote::after {
      content: close-quote; }
  .featured-program .program-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    line-height: 16px;
    color: rgba(38, 38, 38, 0.5);
    font-size: 13px; }

.home-page .radio-schedule {
  flex: 1 1 auto;
  border-radius: 6px;
  box-shadow: 0 7px 9px rgba(0, 0, 0, 0.02); }

.bottom-app-bar {
  display: flex;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  height: 56px;
  overflow: hidden;
  user-select: none;
  box-sizing: content-box; }
  .bottom-app-bar.expanded {
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12); }
  .bottom-app-bar .item {
    display: flex;
    position: relative;
    flex: 1 0;
    flex-flow: column;
    justify-content: center;
    max-width: 168px;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgba(6, 4, 110, 0.5);
    font-size: 12px; }
    .bottom-app-bar .item.active, .bottom-app-bar .item:hover, .bottom-app-bar .item:active {
      color: #06046e; }
    .bottom-app-bar .item .label {
      overflow: hidden;
      text-overflow: ellipsis; }
  .bottom-app-bar mwc-ripple {
    --mdc-theme-primary: #06046e; }
  .bottom-app-bar mwc-icon {
    --mdc-icon-size: 24px; }

.mini-player {
  --bottom-margin: 56px;
  z-index: 2;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }

.settings-list {
  border-radius: 4px;
  background-color: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  .settings-list .row {
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box; }
    .settings-list .row:visited {
      color: rgba(0, 0, 0, 0.87); }
    .settings-list .row.two {
      height: 64px; }
    .settings-list .row:last-child {
      border-bottom: 0; }
    .settings-list .row .icon {
      margin-right: 32px; }
      .settings-list .row .icon.sleep {
        transform: rotate(140deg); }
    .settings-list .row .title {
      font-size: 16px; }
    .settings-list .row .subtitle {
      color: rgba(38, 38, 38, 0.5);
      font-size: 12px; }
    .settings-list .row select {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%; }

.category-page .category-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.category-page .category-info {
  padding: 16px 16px 0; }
  .category-page .category-info text-preview {
    color: rgba(38, 38, 38, 0.5);
    font-size: 14px; }
  .category-page .category-info .picture-wrapper {
    position: relative;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    height: 158px;
    overflow: hidden; }
  .category-page .category-info .picture {
    position: absolute;
    width: 100%;
    height: 100%;
    color: transparent;
    object-fit: cover; }

.category-page .thumbnail-listing .title {
  padding: 8px 8px 0;
  text-align: left;
  font-size: 13px; }

.playlist-page .playlist-title {
  margin: 0;
  padding: 15px;
  text-align: center;
  line-height: 1;
  color: rgba(0, 0, 0, 0.87);
  font-size: 17px;
  font-weight: 700; }

.playlist-page .playlist-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.playlist-page .playlist-info {
  padding: 16px 16px 0;
  text-align: center; }
  .playlist-page .playlist-info text-preview {
    text-align: left;
    color: rgba(38, 38, 38, 0.5);
    font-size: 14px; }
  .playlist-page .playlist-info .picture {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.09) 0 1px 7px;
    width: 158px;
    height: 158px;
    object-fit: cover; }

.playlist-page .thumbnail-listing .title {
  padding: 8px 8px 0;
  text-align: left;
  font-size: 13px; }

.thumbnail-listing {
  display: grid;
  padding: 0 8px 32px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  grid-template-columns: 50% 50%;
  place-items: start stretch; }
  .thumbnail-listing thumbnail-item {
    padding-top: 24px; }
  .thumbnail-listing .title {
    margin: 0;
    padding-top: 8px;
    text-align: center;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: bold; }
  @media (min-width: 356px) {
    .thumbnail-listing {
      grid-template-columns: repeat(auto-fill, minmax(170px, 1fr)); } }

.player-page {
  color: rgba(0, 0, 0, 0.87); }
  .player-page .player-group {
    --mdc-theme-secondary: #06046e;
    display: flex;
    flex: 1 1 auto;
    flex-flow: column;
    justify-content: space-evenly;
    margin: 0 auto;
    padding: 24px 24px 0;
    width: 100%;
    max-width: 334px;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch; }
  .player-page .artwork-wrapper {
    position: relative;
    padding-top: 100%; }
  .player-page .artwork {
    position: absolute;
    top: 0;
    border: 0;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.09);
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .player-page .title-group {
    text-align: center; }
  .player-page .title {
    font-size: 21px;
    font-weight: 700; }
  .player-page .subtitle {
    color: rgba(38, 38, 38, 0.5);
    font-size: 16px; }
  .player-page .track-progress .progress {
    width: 100%;
    height: 30px; }
  .player-page .track-progress .times {
    display: flex;
    justify-content: space-between;
    color: rgba(38, 38, 38, 0.5);
    font-size: 12px;
    font-weight: 600; }
  .player-page .controls {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    padding-bottom: 10px;
    height: 90px; }
    .player-page .controls .sleep-timer,
    .player-page .controls .share {
      --mdc-icon-size: 20px;
      align-self: flex-end; }
    .player-page .controls .spacer {
      flex: 1 1 auto; }
    .player-page .controls .sleep-timer {
      transform: translateX(-12px); }
      .player-page .controls .sleep-timer .icon {
        transform: rotate(140deg); }
        .player-page .controls .sleep-timer .icon[data-time]:not([data-time='0'])::after {
          display: block;
          position: absolute;
          right: 15px;
          bottom: 5px;
          transform: rotate(-140deg);
          line-height: 10px;
          color: rgba(0, 0, 0, 0.87);
          font-family: sans-serif;
          font-size: 10px;
          content: attr(data-time); }
      .player-page .controls .sleep-timer .trigger {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        appearance: none; }
    .player-page .controls .share {
      transform: translateX(12px); }
    .player-page .controls .prev,
    .player-page .controls .next {
      --mdc-icon-size: 40px;
      color: var(--mdc-theme-secondary); }
    .player-page .controls .play {
      --mdc-theme-secondary: #fff;
      --mdc-theme-on-secondary: #06046e;
      margin: 0 24px; }

.not-available {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 16px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  box-sizing: border-box; }

.platform-ios .page {
  padding-top: var(safe-area-inset-top);
  padding-top: env(safe-area-inset-top); }
  .platform-ios .page .page-title.home {
    margin-top: 0; }
  .platform-ios .page.dialog {
    padding-bottom: var(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom); }

.platform-ios .page-header {
  margin-top: 0; }

.platform-ios .bottom-app-bar {
  padding-bottom: var(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom); }
