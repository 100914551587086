@import '../variables/sizes';
@import '../variables/colors';

.featured-program {
    $elevation: rgba(0, 0, 0, .09);

    margin: 0 $size-aqua $size-teal;
    border-radius: 6px;
    box-shadow: 0 1px 7px $elevation;
    background-color: $white;
    padding: $size-teal $size-violet;

    .title {
        margin: 0;
        text-align: center;
        line-height: 22px;
        color: $primary;
        font-size: 19px;
        font-weight: 700;
    }

    .subtitle {
        text-align: center;
        line-height: 20px;
        color: $subtitle;
        font-size: 13px;
    }

    .primary-quote {
        margin-top: $size-aqua;
        text-align: center;
        line-height: 22px;
        color: $primary;
        font-size: 18px;
        font-weight: 600;
        font-style: italic;
        quotes: '„' '”';

        &::before {
            content: open-quote;
        }

        &::after {
            content: close-quote;
        }
    }

    .program-time {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: $size-teal;
        line-height: 16px;
        color: $subtitle;
        font-size: 13px;
    }
}
