@import '../variables/colors';
@import '../variables/sizes';
@import '../mixins/define';

.page-header {
    position: relative;
    flex: 0 0 auto;
    margin: $size-aqua 0;
    padding: 0 $size-aqua;
    text-align: center;
    line-height: 1;
    color: $primary;
    font-size: 17px;
    font-weight: 700;

    .back {
        @include define('--mdc-icon-size', 40px);

        position: absolute;
        top: 50%;
        left: $size-aqua;
        transform: translateY(-50%);
        color: $primary;
    }
}
