@import '../variables/colors';

.category-page {

    .category-content {
        overflow: auto;
        #{'-webkit-overflow-scrolling'}: touch;
    }

    .category-info {
        padding: $size-aqua $size-aqua 0;

        text-preview {
            color: $subtitle;
            font-size: 14px;
        }

        .picture-wrapper {
            position: relative;
            border-radius: 5px;
            background-color: $artwork-placeholder-color;
            height: 158px;
            overflow: hidden;
        }

        .picture {
            position: absolute;
            width: 100%;
            height: 100%;
            color: transparent;
            object-fit: cover;
        }
    }

    .thumbnail-listing {
        .title {
            padding: $size-pink $size-pink 0;
            text-align: left;
            font-size: 13px;
        }
    }
}
