@import '../variables/colors';

.page {
    display: flex;
    flex-flow: column;
    z-index: 1;
    background-color: $white;
    background-image: url('./assets/images/background.png');
    background-size: 100% 100%;
    overflow: hidden;
    box-sizing: border-box;

    .page-title {
        color: $primary;

        &.home {
            margin: 16px 0 0;
            text-align: center;
            line-height: 31px;
            font-size: 25px;
            font-weight: 300;
        }
    }

    &.dialog {
        position: absolute;
        transition: visibility 0s linear .2s;
        animation: modal-page-leave .2s;
        visibility: hidden;
        z-index: 100;
        width: 100%;
        height: 100%;

        &[active] {
            transition: none;
            animation: modal-page-enter .2s;
            visibility: visible;
        }
    }
}

@keyframes modal-page-enter {
    0% {
        transform: translate3d(0, 6%, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes modal-page-leave {
    0% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, 6%, 0);
        opacity: 0;
    }
}
