.playlist-page {
    .playlist-title {
        margin: 0;
        padding: 15px;
        text-align: center;
        line-height: 1;
        color: $black;
        font-size: 17px;
        font-weight: 700;
    }

    .playlist-content {
        overflow: auto;
        #{'-webkit-overflow-scrolling'}: touch;
    }

    .playlist-info {
        padding: $size-aqua $size-aqua 0;
        text-align: center;

        text-preview {
            text-align: left;
            color: $subtitle;
            font-size: 14px;
        }

        .picture {
            border-radius: 5px;
            box-shadow: $box-shadow 0 1px 7px;
            width: 158px;
            height: 158px;
            object-fit: cover;
        }
    }

    .thumbnail-listing {
        .title {
            padding: $size-pink $size-pink 0;
            text-align: left;
            font-size: 13px;
        }
    }
}
