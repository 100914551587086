@import 'variables/colors';
@import 'mixins/define';

html,
body {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

body {
    @include define('--mdc-icon-font', 'Material Icons Sharp');

    display: flex;
    flex-flow: column;
    margin: 0;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: cover;
    padding: 0;
    color: $black;
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
}

* {
    user-select: none;
    -webkit-touch-callout: none; // sass-lint:disable-line no-vendor-prefixes
    -webkit-tap-highlight-color: transparent; // sass-lint:disable-line no-vendor-prefixes
}

:focus {
    outline: none;
}
