@import '../variables/colors';
@import '../variables/sizes';
@import '../mixins/define';

.player-page {
    $artwork-shadow: rgba(0, 0, 0, .09);

    color: $black;

    .player-group {
        @include define('--mdc-theme-secondary', $primary);

        display: flex;
        flex: 1 1 auto;
        flex-flow: column;
        justify-content: space-evenly;
        margin: 0 auto;
        padding: $size-teal $size-orange 0;
        width: 100%;
        max-width: 334px;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
    }

    .artwork-wrapper {
        position: relative;
        padding-top: 100%;
    }

    .artwork {
        position: absolute;
        top: 0;
        border: 0;
        box-shadow: 0 1px 7px $artwork-shadow;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .title-group {
        text-align: center;
    }

    .title {
        font-size: 21px;
        font-weight: 700;
    }

    .subtitle {
        color: $subtitle;
        font-size: 16px;
    }

    .track-progress {
        .progress {
            width: 100%;
            height: 30px;
        }

        .times {
            display: flex;
            justify-content: space-between;
            color: $subtitle;
            font-size: 12px;
            font-weight: 600;
        }
    }

    .controls {
        display: flex;
        flex: 0 1 auto;
        align-items: center;
        justify-content: center;
        margin-top: $size-gray;
        padding-bottom: 10px;
        height: 90px;

        .sleep-timer,
        .share {
            @include define('--mdc-icon-size', 20px);

            align-self: flex-end;
        }

        .spacer {
            flex: 1 1 auto;
        }

        .sleep-timer {
            transform: translateX(-12px);

            .icon {
                transform: rotate(140deg);

                &[data-time] {
                    &:not([data-time='0']) {
                        &::after {
                            display: block;
                            position: absolute;
                            right: 15px;
                            bottom: 5px;
                            transform: rotate(-140deg);
                            line-height: 10px;
                            color: $black;
                            font-family: sans-serif;
                            font-size: 10px;
                            content: attr(data-time);
                        }
                    }
                }
            }

            .trigger {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
                appearance: none;
            }
        }

        .share {
            transform: translateX(12px);
        }

        .prev,
        .next {
            @include define('--mdc-icon-size', 40px);

            color: var(--mdc-theme-secondary);
        }

        .play {
            @include define('--mdc-theme-secondary', $white);
            @include define('--mdc-theme-on-secondary', $primary);

            margin: 0 $size-teal;
        }
    }
}
