.platform-ios {
    .page {
        padding-top: var(safe-area-inset-top);
        padding-top: env(safe-area-inset-top); // sass-lint:disable-line no-duplicate-properties

        .page-title {
            &.home {
                margin-top: 0;
            }
        }

        &.dialog {
            padding-bottom: var(safe-area-inset-bottom);
            padding-bottom: env(safe-area-inset-bottom); // sass-lint:disable-line no-duplicate-properties
        }
    }

    .page-header {
        margin-top: 0;
    }

    .bottom-app-bar {
        padding-bottom: var(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom); // sass-lint:disable-line no-duplicate-properties
    }
}
