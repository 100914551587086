
$black: rgba(0, 0, 0, .87);
$primary: #06046e;
$accent: #8fcbfd;
$white: #fff;
$subtitle: rgba(38, 38, 38, .5);
$divider: rgba(0, 0, 0, .12);
$arrow-color: #2f3ba2;
$lightgray: #d3d3d3;
$box-shadow: rgba(0, 0, 0, .09);
$artwork-placeholder-color: rgba(0, 0, 0, .1);
