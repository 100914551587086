.not-available {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 16px;
    width: 100%;
    max-width: 400px;
    text-align: center;
    box-sizing: border-box;
}
