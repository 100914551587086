@import '../variables/colors';
@import '../variables/elevation';
@import '../mixins/define';

.bottom-app-bar {
    display: flex;
    justify-content: center;
    z-index: 2;
    box-shadow: $elevation-8;
    background-color: $white;
    height: 56px;
    overflow: hidden;
    user-select: none;
    box-sizing: content-box;

    &.expanded {
        box-shadow: $elevation-1;
    }

    .item {
        display: flex;
        position: relative;
        flex: 1 0;
        flex-flow: column;
        justify-content: center;
        max-width: 168px;
        text-align: center;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: change-color($primary, $alpha: .5);
        font-size: 12px;

        &.active,
        &:hover,
        &:active {
            color: $primary;
        }

        .label {
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    mwc-ripple {
        @include define('--mdc-theme-primary', $primary);
    }

    mwc-icon {
        @include define('--mdc-icon-size', 24px);
    }
}
