$size-base: 8px;
$size-2: $size-base * 2;
$size-3: $size-base * 3;
$size-4: $size-base * 4;
$size-5: $size-base * 5;

$size-gray: $size-base / 2;
$size-pink: $size-base;
$size-aqua: $size-2;
$size-teal: $size-3;
$size-orange: $size-3;
$size-violet: $size-4;

// viewport for mobile from sketch
$size-s: 356px;

$viewport-s: 'min-width: #{$size-s}';
