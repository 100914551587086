.thumbnail-listing {
    display: grid;
    padding: 0 $size-pink $size-violet;
    overflow: auto;
    #{'-webkit-overflow-scrolling'}: touch;
    grid-template-columns: 50% 50%;
    place-items: start stretch;

    thumbnail-item {
        padding-top: $size-orange;
    }

    .title {
        margin: 0;
        padding-top: $size-pink;
        text-align: center;
        text-decoration: none;
        color: $black;
        font-size: 14px;
        font-weight: bold;
    }

    @media($viewport-s) {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
}
