.settings-list {
    border-radius: 4px;
    background-color: $white;
    overflow: auto;
    -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes

    .row {
        display: flex;
        position: relative;
        align-items: center;
        border-bottom: 1px solid $divider;
        padding: 16px;
        text-decoration: none;
        color: $black;
        box-sizing: border-box;

        &:visited {
            color: $black;
        }

        &.two {
            height: 64px;
        }

        &:last-child {
            border-bottom: 0;
        }

        .icon {
            margin-right: 32px;

            &.sleep {
                transform: rotate(140deg);
            }
        }

        .title {
            font-size: 16px;
        }

        .subtitle {
            color: $subtitle;
            font-size: 12px;
        }

        select {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
        }
    }
}
